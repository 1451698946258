import { template as template_33dde32a0ecf497e9a153c0f2ece8b8c } from "@ember/template-compiler";
const FKText = template_33dde32a0ecf497e9a153c0f2ece8b8c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
