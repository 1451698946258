import { template as template_f941aa3ec47e42ef98d418df58e88535 } from "@ember/template-compiler";
const FKLabel = template_f941aa3ec47e42ef98d418df58e88535(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
